import api from './api';

class RegistroFotograficoService {

  getItems(cadenaBusqueda) {
    cadenaBusqueda = cadenaBusqueda ? cadenaBusqueda : ''
    return api.get(`/api/registros_fotograficos${cadenaBusqueda}`);
  }

  consultar(params) {
    return api.post(`/api/registros_fotograficos/consultar`, params)
  }
  consultar2(params) {
    return api.post(`/api/registros_fotograficos/consultar2`, params)
  }
  consultarVitrina(params) {
    return api.post(`/api/registros_fotograficos_vitrina/consultar`, params)
  }

  uploadFile(file) {
    return api.post(`/api/registros_fotograficos/cargar`, file, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  getArchivo(idTemp) {
    return api.get(`/api/registros_fotograficos/descargar/${idTemp}`, { responseType: 'blob' });
  }

  getArchivoZip(idTemp) {
    return api.get(`/api/registros_fotograficos/descargar_zip/${idTemp}`, { responseType: 'blob' });
  }

  getArchivoPdf(idTemp) {
    return api.get(`/api/registros_fotograficos/descargar_pdf/${idTemp}`, { responseType: 'blob' });
  }


  getNombresFotos(placaProcesoAnoMes) {
    return api.get(`/api/registros_fotograficos/fotos/${placaProcesoAnoMes}`);
  }

  getNombresFotosPendientes(criterio) {
    return api.get(`/api/fotos_pendientes/${criterio}`);
  }

  trasladar(params) {
    return api.post(`/api/registros_fotograficos/trasladar`, params)
  }

  eliminarRegistros(params) {
    return api.post(`/api/registros_fotograficos/eliminar`, params)
  }


  reaprovecharActivo(data) {
    return api.post(`/api/registros_fotograficos/reaprovechar`, data);
  }

  interesActivos(params) {
    return api.post(`/api/registros_fotograficos/info`, params);
  }
  

}

export default new RegistroFotograficoService();
