<template>
  <div>
    <v-card elevation="2" tile>
      <v-card-title style="float: left">Registros Fotográficos Vitrina</v-card-title>

      <v-card-text>
        
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header> Filtros de búsqueda</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container>
                <v-form v-model="valid">
                  <v-row>
                    <v-col cols="12" md="12">
                      <template v-for="x, index in subConsulta.campos_busqueda">
                        </hr>
                      </template>
                      
                      <table class="tabla">
                        <thead class="v-data-table-header">
                          <tr>
                            <th class="text-left">
                              Filtro
                            </th>
                            <th class="text-left">
                              Valor
                            </th>
                            <th class="text-center">
                              Eliminar
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="item in subConsulta.campos_busqueda"
                            :key="item.name"
                          >
                            <td>
                              <div
                                v-if="loadingCampos"
                                class="spinner-border"
                                role="status"
                              ></div>
                              <v-select
                                  v-if="!loadingCampos && item != subConsulta.campos_busqueda[0]"
                                  v-model="item.filtro"
                                  :items="campos"
                                  :item-text="'text'"
                                  :item-value="'value'"
                                  attach
                                  label="Criterio de búsqueda"
                                  :rules="required"
                                ></v-select>
                                <v-select
                                  v-if="!loadingCampos && item == subConsulta.campos_busqueda[0]"
                                  v-model="item.filtro"
                                  :items="campos"
                                  :item-text="'text'"
                                  :item-value="'value'"
                                  attach
                                  :disabled="true"
                                  readonly
                                  label="Proceso"
                                  :rules="required"
                                ></v-select>
                            </td>
                            <td>
                              <v-text-field
                              v-if="item.filtro != 'user_id' && item != subConsulta.campos_busqueda[0]"
                                v-model="item.valor"
                                label="Valor"
                                single-line
                                counter
                                hide-details="auto"
                                :rules="required"
                              ></v-text-field>

                              <v-text-field
                              v-if="item.filtro != 'user_id' && item == subConsulta.campos_busqueda[0]"
                                v-model="item.valor"
                                label="Valor"
                                single-line
                                counter
                                hide-details="auto"
                                :rules="required"
                                :disabled="true"
                              ></v-text-field>

                              <v-autocomplete
                                v-if="item.filtro == 'user_id' && users.length"
                                v-model="item.valor"
                                :items="users"
                                item-text="name"
                                item-value="id"
                                label="Escriba o seleccione..."
                                style="padding-top:15px"
                              ></v-autocomplete>
                            </td>
                            <td class="text-center">
                              <v-icon v-if="item != subConsulta.campos_busqueda[0]"
                              small @click="deleteItem(item)"> mdi-delete </v-icon>
                            </td>
                          </tr>
                        </tbody>
                      
                      </table>
                      
                      <v-row>
                        <v-col cols="12" md="12" class="text-right">
                          <CButton
                            v-if="true"
                            color="primary"
                            class="px-4"
                            @click="agregarFiltro()"
                            >+ Filtro</CButton
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  
                  <v-row>
                    <v-col cols="12" md="12" class="text-center">
                      <CButton
                        color="primary"
                        class="px-4"
                        :disabled="!(valid)"
                        @click="consultar()"
                        ><CIcon name="cil-magnifying-glass" /> Obtener consulta</CButton
                      >
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <div class="text-center" v-if="loading">
          <div class="spinner-border" role="status"></div>
        </div>
        
        <v-container v-if="items.length">      
          <v-row>
            <v-col cols="6" md="6">
              <v-text-field
                v-model="search"
                label="Buscar en los resultados"
                class="mx-4"
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6" class="text-right">
              <v-spacer></v-spacer>
              <v-btn
                color="success"
                class="ma-2 white--text"
                @click="descargar()"
                :disabled="loadingDescargar"
              >
                CSV
                <v-icon
                  right
                  dark
                >
                  mdi-file-download
                </v-icon>
                &nbsp;<div class="spinner-border" role="status" v-if="loadingDescargar"></div>
              </v-btn>
              <v-btn
                color="success"
                class="ma-2 white--text"
                @click="descargarZip()"
                :disabled="loadingDescargar"
              >
                ZIP
                <v-icon
                  right
                  dark
                >
                  mdi-file-download
                </v-icon>
                &nbsp;<div class="spinner-border" role="status" v-if="loadingDescargar"></div>
              </v-btn>
              <v-btn
                color="success"
                class="ma-2 white--text"
                @click="descargarPdf()"
                :disabled="loadingDescargar"
              >
                PDF
                <v-icon
                  right
                  dark
                >
                  mdi-file-download
                </v-icon>
                &nbsp;<div class="spinner-border" role="status" v-if="loadingDescargar"></div>
              </v-btn>

            </v-col>
          </v-row>
        </v-container>

        <div v-if="this.items.length > 0 && this.items.length < 5000">
          Número de registros encontrados: <strong>{{this.items.length}}</strong> con <strong>{{total_imagenes}}</strong> imágenes en total.
        </div>
        <div v-if="this.items.length >= 5000">
          Número de registros encontrados: Se encontraron más de 5.000 coincidencias con los criterios de búsqueda seleccionados.  Los primeros 5.000 resultados cuentan con <strong>{{total_imagenes}}</strong> imágenes en total.
        </div>
        
        <v-data-table
          :headers="headers"
          :items="items"
          class="elevation-1"
          v-model="params.selected"
          item-key="id"
          :search="search"
          :footer-props="{
            'items-per-page-options': [10, 50, 100, 200, 500, -1]
          }"
          :items-per-page="50"
          :loading='loading'
          loading-text="Cargando..."
          :show-select="permissions.includes('Eliminar Carga Registro Fotográfico') || permissions.includes('Repositorio Fotográfico - Vitrina')"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon 
              v-if="item.imagenes"
              small
              class="mr-2"
              @click="verImagenes(item)"
            >
              mdi-eye
            </v-icon>
          </template>
        </v-data-table>
        <small>Se visualizarán máximo 5.000 registros.</small>

      </v-card-text>
    </v-card>


    <br><br>

    <template>
      <v-card elevation="2" tile v-if="params.selected.length && permissions.includes('Eliminar Carga Registro Fotográfico')">
    <v-card-title>Modificar {{ params.selected.length }} registros seleccionados</v-card-title>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <div v-if="loadingDelete" class="spinner-border" role="status"></div>
      <div>
        <CButton
          v-if="!loadingDelete"
          color="primary"
          class="px-4"
          @click="Eliminar()"
          style="margin-right: 4px;"
        >
          Eliminar Registros
        </CButton>
      </div>
      <div>
        <CButton
          v-if="!loadingDelete"
          color="primary"
          class="px-4"
          @click="Reaprovechar()"
        >
          Reaprovechar Activo
        </CButton>
      </div>
    </v-card-actions>
  </v-card>
</template>

<br><br>
<template>
  <v-card elevation="2" tile v-if="params.selected.length && permissions.includes('Repositorio Fotográfico - Vitrina')">
    <v-card-title>Solicitar información por {{ params.selected.length }} activos seleccionados</v-card-title>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <div v-if="loadingDelete" class="spinner-border" role="status"></div>
      <div>
        <CButton
          v-if="!loadingDelete"
          color="primary"
          class="px-4"
          @click="Interes()"
          style="margin-right: 4px;"
        >
          Interes por activo
        </CButton>
      </div>

    </v-card-actions>
  </v-card>
</template>




    <br><br>

    <v-dialog
      v-model="dialog_confirm"
      width="70%"
    >
      <v-card>
        <v-card-title primary-title>¿Realmente desea borrar los registros:?</v-card-title>
        <v-card-text>
          
          <li v-for="item in this.params.selected" :key="item.id">
          id: {{ item.id }} - {{item.placa}}_{{item.proceso}}_{{item.ano}}_{{item.mes}}
          </li>
        </v-card-text>
        <v-card-actions>
          <v-btn class="btn_eliminar" @click="ejecutarEliminar()">
            Eliminar
          </v-btn>
          <v-btn class="btn_eliminar" @click="cerrarEliminar()">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <v-dialog
      v-model="dialog_reaprovechar"
      width="70%"
    >
      <v-card>
        <v-card-title primary-title>¿Realmente desea reaprovechar este activo?</v-card-title>
        <v-card-text>
          
          <li v-for="item in this.params.selected" :key="item.id">
          id: {{ item.id }} - {{item.placa}}_{{item.proceso}}_{{item.ano}}_{{item.mes}}
          </li>
        </v-card-text>
        <v-card-actions>
          <v-btn class="btn_eliminar" @click="ejecutarReaprovechar()">
            Reaprovechar
          </v-btn>
          <v-btn class="btn_eliminar" @click="cerrarReaprovechar()">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>


    <br><br>

    <v-dialog
      v-model="dialog_interes"
      width="70%"
    >
      <v-card>
        <v-card-title primary-title>Formulario de solicitud de interés en Activos Fijos</v-card-title>
        <v-card-text>
          <v-card-subtitle style="font-size: 16px; color: black;">Activos seleccionados</v-card-subtitle>
          <li v-for="item in this.params.selected" :key="item.id">
          id: {{ item.id }} - {{item.placa}}_{{item.proceso}}_{{item.ano}}_{{item.mes}}
          </li>

          <v-card-subtitle style="font-size: 16px; color: black;">Por favor ingrese sus datos para recibir retroalimentación de los activos seleccionados</v-card-subtitle>

            <v-text-field
              v-model="nombres"
              density="compact"
              placeholder="Nombres"
              prepend-inner-icon="mdi-account"
              variant="outlined"
            ></v-text-field>

            <v-text-field
              v-model="apellidos"
              density="compact"
              placeholder="Apellidos"
              prepend-inner-icon="mdi-account"
              variant="outlined"
            ></v-text-field>

            <v-text-field
              v-model="correoElectronico"
              density="compact"
              placeholder="Correo electronico"
              prepend-inner-icon="mdi-email-outline"
              variant="outlined"
            ></v-text-field>

            <v-text-field
              v-model="telefono"
              density="compact"
              placeholder="Telefono"
              prepend-inner-icon="mdi-phone"
              variant="outlined"
            ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn class="btn_eliminar" @click="ejecutarInteres()">
            Realizar solicitud
          </v-btn>
          <v-btn class="btn_eliminar" @click="cerrarInteres()">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <br><br>

    <v-dialog
        v-model="dialog"
        transition="dialog-top-transition"
        width="98%"
        height="90"
      >
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar
              color="#BBD000"
              dark
            >{{dialogTitle}}</v-toolbar>
            <v-card-text class="cardImgs">
              <div class="text-center" v-if="loadingListaImagenes">
                <div class="spinner-border" role="status"></div>
              </div>
              <span v-if="!loadingListaImagenes">
                <v-card elevation="4" v-if="imagenZoom != ''">
                  <v-card-text>
                    <v-img
                      v-if="imagenZoom != ''"
                      lazy-src="https://picsum.photos/id/11/10/6"
                      max-height="100%"
                      max-width="100%"
                      :src=imagenZoom
                      @click="zoom('')"
                    ></v-img>
                  </v-card-text>
                </v-card>
                <v-card elevation="4" class="cardImg" v-for="(imagen,index) in imagenes" :key="index" v-if="imagenZoom == ''">
                  <v-card-text>
                    <v-img 
                      lazy-src="https://picsum.photos/id/11/10/6"
                      max-height="153"
                      max-width="263"
                      :src=getURLImagen(imagen)
                      @click="zoom(getURLImagen(imagen))"
                    ></v-img>
                    {{getNombreImagenOriginal(imagen)}}
                  </v-card-text>
                </v-card>
              </span>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                text
                @click="cerrarImagenes()"
              >Volver</v-btn>
            </v-card-actions>
          </v-card>
        </template>
    </v-dialog>
  </div>
</template>

<script>
import registroFotograficoService from "@/services/registroFotografico.service";
import ListaService from "@/services/lista.service";
import CampoService from "@/services/campo.service";
import UserService from "@/services/user.service";
import { mapState } from "vuex";
import EventBus from "@/common/EventBus";
import api from '@/services/api';

export default {
  
  data() {
    return {
      subConsulta: {
        campos_busqueda: [
          { filtro: "Proceso", valor: "MIDA-OI" },
          { filtro: "activo_reaprovechado", valor: "0" },
        ], 
        campos_salida: [],
        idTemp: '',
        currentdate: null,
        consulta: null
      },
      total_imagenes: 0,
      valid: false,
      nombres: '',
      apellidos: '',
      correoElectronico: '',
      telefono: '',
      data:{},
      defaultSelect:[
        { text: "Proceso", value: "proceso" },
      ],
      rules: [
        (value) => !!value || "Requerido",
        (value) => (value && value.length >= 3) || "Mínimo 3 caracteres",
      ],
      required: [(value) => !!value || "Requerido"],
      camposBusqueda: [],
      campos: [
        { text: "Código", value: "id" },
        { text: "Sociedad", value: "sociedad" },
        { text: "Año", value: "ano" },
        { text: "Mes", value: "mes" },
        { text: "Placa", value: "placa" },
        { text: "Fecha de carga", value: "fecha" },
        { text: "Proceso", value: "proceso" },
        { text: "Vicepresidencia", value: "vicepresidencia" },
        { text: "Gerencia", value: "gerencia" },
        { text: "Campo", value: "campo" },
        { text: "Ubicación", value: "ubicacion" },
        { text: "Descripción", value: "descripcion" },
        { text: "Descripción Complementaria", value: "descripcion_complementaria" },
        { text: "TAG", value: "tag" },
        { text: "Fabricante", value: "fabricante" },
        { text: "Modelo", value: "modelo" },
        { text: "Serie", value: "serie" },
        { text: "Capacidad", value: "capacidad" },
        { text: "Unidad de Medida", value: "unidad_de_medida" },
        { text: "Estatus", value: "estatus" },
        { text: "Nombre Contacto de Operación", value: "nombre_contacto_de_operacion" },
        { text: "Correo Contacto de Operación", value: "correo_contacto_de_operacion" },
        { text: "Nombre Profesional Atención Regional", value: "profesional_atencion_regional" },
        { text: "Correo Profesional Atención Regional", value: "correo_profesional_atencion_regional" },
        { text: "Número de imágenes", value: "imagenes" },
        { text: "Usuario", value: "user_id" },
        { text: "Activo reaprovechado", value: "activo_reaprovechado" }
      ],
      opcionesCamposSalida: [],
      headers: [
        { text: "Código", value: "id" },
        { text: "Sociedad", value: "sociedad" },
        { text: "Año", align: 'center', value: "ano" },
        { text: "Mes", align: 'center', value: "mes" },
        { text: "Placa", align: 'center', value: "placa" },
        { text: "Fecha de carga", align: 'center', value: "fecha" },
        { text: "Proceso", align: 'center', value: "proceso" },
        { text: "Vicepresidencia", value: "vicepresidencia" },
        { text: "Gerencia", value: "gerencia" },
        { text: "Campo", value: "campo" },
        { text: "Ubicación", value: "ubicacion" },
        { text: "Descripción", value: "descripcion" },
        { text: "Descripción Complementaria", value: "descripcion_complementaria" },
        { text: "TAG", align: 'center', value: "tag" },
        { text: "Fabricante", value: "fabricante" },
        { text: "Modelo", value: "modelo" },
        { text: "Serie", value: "serie" },
        { text: "Capacidad", align: 'center', value: "capacidad" },
        { text: "Unidad de Medida", align: 'center', value: "unidad_de_medida" },
        { text: "Estatus", align: 'center', value: "estatus" },
        { text: "Nombre Contacto de Operación", value: "nombre_contacto_de_operacion" },
        { text: "Correo Contacto de Operación", value: "correo_contacto_de_operacion" },
        { text: "Nombre Profesional Atención Regional", value: "profesional_atencion_regional" },
        { text: "Correo Profesional Atención Regional", value: "correo_profesional_atencion_regional" },
        { text: "Imágenes cargadas", align: 'center', value: "imagenes" },
        { text: "Días habiles", align: 'center', value: 'dias_habiles' },
        { text: "Ver imágenes", align: 'center', value: 'actions' },
      ],
      items: [],
      users: [],
      imagenes: [],
      baseURL: '',
      search: null,
      params: {
        archivoLayoutId: undefined, 
        selected: [],
      },
      selected: [],

      headersFiltros: [
        { text: "Filtro", value: "filtro", sortable: false },
        { text: "Valor", value: "valor", sortable: false },
        { text: "Borrar", value: "actions", sortable: false },
      ],
      loading: false,
      loadingListaImagenes: false,
      loadingDescargar: false,
      loadingInteres: false,
      loadingLabelsPanoramicos: false,
      loadingCampos: false,
      message: "",
      dialog: false,
      dialog_reaprovechar:false,
      dialog_confirm:false,
      dialog_interes:false,
      loadingDelete: false,
      dialogTitle: "",
      imagenZoom: "",
      tab: null,
      itemsTabs: [
        '.', '..', '...', '....',
      ],
      itemPanoramico: undefined
    };
  },
  components: {

  },
  watch: {
    
  },
  computed: {
    ...mapState(["permissions"])
  },
  mounted() {
    if (!this.permissions.includes("Repositorio Fotográfico - Vitrina"))
      this.$router.push("/dashboard");
      
    this.baseURL = api.defaults.baseURL
    if( this.baseURL.charAt(this.baseURL.length - 1) != '/'){
      this.baseURL = this.baseURL + '/'
    }
    //this.fetchCampos("")
    this.fetchUsers("")
    
    //this.agregarFiltro()
  },
  methods: {
    fetchCampos(cadenaBusqueda) {
      this.loadingCampos = true;
      if(cadenaBusqueda=="")
        cadenaBusqueda="?activo=1";
      else
        cadenaBusqueda=cadenaBusqueda+"&activo=1";
      CampoService.getItems(this.subConsulta.layout_id, cadenaBusqueda).then(
        (response) => {
          this.campos = response.data;
          
          this.campos = this.campos.map(
            function f(x) {
              let alias = x.alias?x.alias:x.nombre
              return { ...x, nombre_alias: `${alias}` };
            }
          );
          //this.opcionesCamposSalida = this.campos.filter( item => this.consulta.campos_consulta.includes(item.nombre))
          this.loadingCampos = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingCampos = false;
        }
      );
    },
    fetchUsers(cadenaBusqueda) {
      this.loading = true;
      UserService.getItems(cadenaBusqueda).then(
        (response) => {
          this.users = response.data;
          //console.log(this.users);
          this.loading = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loading = false;
        }
      );
    },
    Eliminar(){
      this.loadingDelete = true;
      this.dialog_confirm=true;
    },
    cerrarEliminar(){
      this.loadingDelete = false;
      this.dialog_confirm=false;
    },
    ejecutarEliminar(){
      this.dialog_confirm=false;
      registroFotograficoService.eliminarRegistros(this.params).then(
        (response) => {
          this.loadingDelete = false;
          this.items=[];
          this.params.selected= [];
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingDelete = false;
          this.items=[];
        }
      )
    },
    Interes(){
      this.loadingInteres = true;
      this.dialog_interes= true;
    },
    cerrarInteres(){
      this.loadingInteres = false;
      this.dialog_interes= false;
    },
    ejecutarInteres(){
      this.dialog_interes=false;

      this.params.users = {};
      this.params.user = {
        nombre: this.nombres,
        apellido: this.apellidos,
        email: this.correoElectronico,
        telefono: this.telefono
      };

      registroFotograficoService.interesActivos(this.params).then(
        (response) => {
          this.loadingDelete = false;
          this.items=[];
          this.params.selected= [];
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingDelete = false;
          this.items=[];
        }
      )
    },

    Reaprovechar(){
      console.log("reaprovechar__")
      this.loadingDelete = true;
      this.dialog_reaprovechar=true;
      console.log("Reaprovechar")

    },
    cerrarReaprovechar(){
      this.loadingDelete = false;
      this.dialog_reaprovechar=false;
    },
    ejecutarReaprovechar() {
  this.dialog_reaprovechar = false;
  this.loadingDelete = true;

  const ids = this.params.selected.map(item => item.id);
  const activoReaprovechado = "1";
  
  const data = {
    data: {
      selected: {
        activo_reaprovechado: activoReaprovechado
      }
    },
    ids: ids
  };

  registroFotograficoService.reaprovecharActivo(data).then(
    (response) => {
      this.loadingDelete = false;
      this.items = [];
      this.params.selected = [];
    },
    (error) => {
      this.content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      this.loadingDelete = false;
      this.items = [];
    }
  );
},



    getCampoById(id){
      let campo = this.campos.find(e => e.id == id)
      return campo?campo.nombre:''
    },
    getAliasById(id){
      let campo = this.campos.find(e => e.id == id)
      return campo?campo.nombre_alias:''
    },
    getAliasByNombre(nombre){
      let campo = this.campos.find(e => e.nombre == nombre)
      return campo?campo.nombre_alias:''
    },
    getValorCampoByNombre(nombre, item){
      return item?item[nombre]:''
    },
    consultar() {
      this.loading = true
      this.total_imagenes = 0
          this.subConsulta.currentdate = new Date()
      this.subConsulta.idTemp = 'Temp'+this.hashCode(JSON.stringify(this.subConsulta)) 
      
      registroFotograficoService.consultarVitrina(this.subConsulta).then(
        (response) => {
         
          // console.log("Resultado consulta:")
          // console.log(response.data)
          this.items = response.data
          for(let i=0; i< this.items.length; i++)
            this.total_imagenes += this.items[i].imagenes
          this.loading = false
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
            console.log("presentación del error:")
            console.log(error)
            this.loading = false
        }
      )
    },
    agregarFiltro() {
      this.subConsulta.campos_busqueda.push({
        filtro: "",
        valor: "",
      });
    },
    deleteItem(item) {
      let i = this.subConsulta.campos_busqueda.indexOf(item);
      this.subConsulta.campos_busqueda.splice(i, 1);
    },
    verImagenes(item){
      // console.log(item)
      this.dialogTitle = `Código: ${item.id} - Placa: ${item.placa} - Proceso: ${item.proceso} - Año: ${item.ano} - Mes: ${item.mes}`
      this.getNombresFotos(`${item.placa}_${item.proceso}_${item.ano}_${item.mes}`)
      
      this.dialog = true
    },
    getNombresFotos(placaProcesoAnoMes) {
      this.imagenes = []
      this.loadingListaImagenes = true
      
      registroFotograficoService.getNombresFotos(placaProcesoAnoMes).then(
        (response) => {
         
          // console.log("Nombres Fotos:::")
          // console.log(response.data)
          this.imagenes = response.data
          this.loadingListaImagenes = false
          
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
            console.log("presentación del error:")
            console.log(error)
            this.loadingListaImagenes = false
        }
      )
    },
    zoom(imagen) {
      this.imagenZoom = imagen
    },
    getNombreImagenOriginal(imagen){
      let nombre = imagen.split('/')
      nombre = nombre[nombre.length-1]
      nombre = nombre.split('_')
      return `${nombre[0]}_${nombre[1]}` 
    },
    getURLImagen(imagen){
      console.log(`${this.baseURL}api/registros_fotograficos/foto/${imagen}`)
      return `${this.baseURL}api/registros_fotograficos/foto/${imagen}`
    },
    cerrarImagenes(){
      this.zoom('')
      this.dialog = false
    },
    descargar() {
      this.loadingDescargar = true
      registroFotograficoService.getArchivo(this.subConsulta.idTemp).then(
        (response) => {
          var blob = new Blob([response.data], {
            type: "application/octet-stream",
          });
          var fileName = `Consulta_Registro_Fotografico.csv`;
          this.saveAs(blob, fileName);
          this.loadingDescargar = false
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
            this.loadingDescargar = false
        }
      );
    },
    descargarZip() {
      this.loadingDescargar = true
      registroFotograficoService.getArchivoZip(this.subConsulta.idTemp).then(
        (response) => {
          var blob = new Blob([response.data], {
            type: "application/octet-stream",
          });
          var fileName = `Consulta_Registro_Fotografico.zip`;
          this.saveAs(blob, fileName);
          this.loadingDescargar = false
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
            this.loadingDescargar = false
        }
      );
    },
    descargarPdf() {
      this.loadingDescargar = true
      registroFotograficoService.getArchivoPdf(this.subConsulta.idTemp).then(
        (response) => {
          var blob = new Blob([response.data], {
            type: "application/octet-stream",
          });
          var fileName = `Consulta_Registro_Fotografico.pdf`;
          this.saveAs(blob, fileName);
          this.loadingDescargar = false
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
            this.loadingDescargar = false
        }
      );
    },
    saveAs(blob, fileName) {
      var url = window.URL.createObjectURL(blob);

      var anchorElem = document.createElement("a");
      anchorElem.style = "display: none";
      anchorElem.href = url;
      anchorElem.download = fileName;

      document.body.appendChild(anchorElem);
      anchorElem.click();

      document.body.removeChild(anchorElem);

      // On Edge, revokeObjectURL should be called only after
      // a.click() has completed, atleast on EdgeHTML 15.15048
      setTimeout(function () {
        window.URL.revokeObjectURL(url);
      }, 1000);
    },
    hashCode (cadena) {
      var hash = 0,
        i, chr;
      if (cadena.length === 0) return hash;
      for (i = 0; i < cadena.length; i++) {
        chr = cadena.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
      }
      return hash;
    },
    depurar_cadena (text) {
      text = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      text = text.replace(/ /g,"_");
      text = text.replace(/"""KEY"""/g,"_key");
      if (text == "KEY")
        text = "_key"
      text = text.toLowerCase();
      return text
    },
  },
};
</script>

<style>

.v-dialog__content {
    z-index: 1030 !important;
}
.panoramico .v-input__control {
  height: 30px;
}

.tabla{
  width: 100%;
  border-width: 0.5px;
}
.tabla th{
  color: rgba(0,0,0,.6);
  font-size: .75rem;
  padding-left: 16px;
  padding-right: 16px;
  height: 48px;
}
.tabla, .tabla tr {
  border: 1px solid;
  border-collapse: collapse;
  border-color: #e1e1e1;
}
.tabla td {
  padding-left: 16px;
  padding-right: 16px;
}

.cardImgs {
  overflow:auto;
}

.cardImg {
  margin: 1%;
  width: 28%;
  float: left;
}
</style>
